import axios from "axios"
import JwtService from "@/core/services/jwt.service";
import colors from "vuetify/lib/util/colors";

require("dotenv").config();

let studentsApiClient;

export const studentService = {
    init() {
        let baseURL;
        if (process.env.GE_STUDENTS_APP_ENV === "production") {
            baseURL = process.env.GE_STUDENTS_API_URL;
        } else if (process.env.GE_STUDENTS_APP_ENV === "development") {
            baseURL = process.env.GE_STUDENTS_DEV_API_URL;
        } else {
            baseURL = process.env.GE_STUDENTS_LOCAL_API_URL;
        }

        studentsApiClient = axios.create({
            baseURL,
            headers: {
                Authorization: `${JwtService.getToken()}`,
                'Content-Type': 'application/json',
            },
        });

    },
    /**
    * Set authentication header 
     * @param resource
     * @param slug
     * @returns {*}
     */

    setHeader() {
        studentsApiClient.defaults.headers.common[
            "Authorization"
        ] = `${JwtService.getToken()}`;
    },

    /**
     * Send GET request
     */

    get(resource, slug = "", headers = "") {

        let path;
        if (slug === "") {
            path = resource;
        } else if (Array.isArray(slug)) {
            const plainSlug = slug.join("/");
            path = `${resource}/${plainSlug}`;
        } else {
            path = `${resource}/${slug}`;
        }
        return studentsApiClient.get(path).catch((error) => {
            throw new Error(`[KT] ApiService ${error}`);
        })

    },

    /**
     * Send POST request
     */

    post(resource, params) {
        return studentsApiClient.post(resource, params);
    },

    /**
     * send PUT request
     */

    update(resource, slug, params) {
        return studentsApiClient.put(`${resource}/${slug}`, params);
    },

    /**
     * Send Delete request
     */
    delete(resource) {
        return studentsApiClient.delete(resource).catch((error) => {
            throw new Error(`[RWV] ApiService ${error}`)
        })
    }
}
