import Vue from "vue";
import Vuelidate from "vuelidate";
import VuelidateErrorExtractor from "vuelidate-error-extractor";

Vue.use(Vuelidate);

Vue.use(VuelidateErrorExtractor, {
  messages: {
    required: "El campo {attribute} es obligatorio",
    email:
      "El campo {attribute} no es una dirección de correo electrónico adecuada",
    minLength: "El campo {attribute} deberia contener al menos {min} caracteres",
    maxLength: "El campo {attribute} deberia contener máximo {max} caracteres",
    alpha: "El campo { attributes } solo acepta caracteres alfabeticos",
    alphaNum: "El campo { attribute } solo acepta caracteres alfanúmericos",
    numeric: "El campo { attribute } solo acepta números",
    integer: "El campo { attribute } solo acepta números positivos",
    decimal: "El campo { attribute } solo acepta números enteros o decimales",
    minValue: "El valor minimo aceptado por este campo es {min}",
    maxValue: "El valor máximo aceptado por este campo es {max}",
  },
  attributes: {
    email: "correo electrónico",
  },
});
