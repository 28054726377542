import ApiService from "@/core/services/api.service";
import UUIDservice from "@/core/services/uid.service";

require("dotenv").config();

// action types
export const FETCH_USER_TYPES = "fetchUserTypes";
export const FETCH_PERMISSIONS = "fetchPermissions";
export const FETCH_ACTIONS_PERMISSIONS = "fetchActionsPermissions";
export const FETCH_USERS = "fetchUsers";
export const FETCH_STUDENT_UUID = "fetchStudentUUID";
export const GET_CURRENT_PAGE_ACTIONS = "getCurrentPageActions";
// export const GET_USERS_BY_USER_TYPE = "getUsersByUserType";

// mutation types
export const SET_UUID_AS_HEADER = "setUUIDasHeader";
export const SET_USER_TYPE = "setUserType";
export const SET_PERMISSIONS = "setPermissions";
export const SET_ACTIONS = "setActions";
export const SET_CURRENT_PAGE_ACTIONS = "setCurrentPageActions";
export const PURGE_CURRENT_PAGE_ACTIONS = "clearCurrentPageActions";
export const SET_USERS = "setUsers";

const state = {
  idApp: process.env.CORE_ID_APP,
  userType: {},
  permissions: [],
  actions: [],
  currentPageActions: [],
  studentUUID: [],
  users: [],
};

const getters = {
  idApp(state) {
    const idAppParsed = Number(state.idApp);
    return idAppParsed;
  },
  permissions(state) {
    return state.permissions;
  },
  actions(state) {
    return state.actions;
  },
  userType(state) {
    return state.userType;
  },
  currentPageActions(state) {
    return state.currentPageActions;
  },
  studentUUID(state) {
    return state.studentUUID;
  },
  users(state) {
    return state.users;
  },
  getUsersByUserType: (state) => (userTypes) => {
    return state.users.filter((item) => userTypes.includes(item.user_type));
  },
};

const actions = {
  async [FETCH_STUDENT_UUID](context, code) {
    //uuid petición
    let response;
    try {
      response = await fetch(
        `https://apiform.ricaldone.edu.sv/api/students/code/${code}`
      );
      if (response.ok) {
        context.commit(SET_UUID_AS_HEADER, await response.json());
      }
    } catch (error) {}
  },
  [FETCH_USER_TYPES](context) {
    // Retrieving all user types
    ApiService.get(context.getters.coreApiUrl + "access/user_type").then(
      ({ data }) => {
        if (!("response" in data) && !data.response) {
          // Filtering data for get the user type of actual user
          let filteredData = data.find(
            (item) => item.id_application == context.getters.idApp
          );
          context.commit(SET_USER_TYPE, filteredData);
        }
      }
    );
  },
  [FETCH_PERMISSIONS](context) {
    // Retrieving all permissions
    ApiService.get(context.getters.coreApiUrl + "access/permission").then(
      ({ data }) => {
        if (!("response" in data) && !data.response) {
          // Filtering permissions of this application
          let filteredData = data.filter(
            (item) => item.id_application == context.getters.idApp
          );
          context.commit(SET_PERMISSIONS, filteredData);
        }
      }
    );
  },
  [FETCH_ACTIONS_PERMISSIONS](context) {
    // Retrieving all actions
    return new Promise((resolve) => {
      ApiService.get(
        context.getters.coreApiUrl + "access/action_permission"
      ).then(({ data }) => {
        if (!("response" in data) && !data.response) {
          // Filtering actions of this application
          let filteredData = data.filter(
            (item) => item.id_application == context.getters.idApp
          );
          context.commit(SET_ACTIONS, filteredData);
        }
        resolve();
      });
    });
  },
  [GET_CURRENT_PAGE_ACTIONS](context, page) {
    const filterAndSetActions = () => {
      const pageActions = context.getters.actions.filter(
        (item) => item.permission == page
      );
      context.commit(SET_CURRENT_PAGE_ACTIONS, pageActions);
    };

    // se verifica si no hay permisos para mandar a traerlos y luego filtrarlos
    if (Object.keys(context.getters.permissions).length == 0) {
      context.dispatch(FETCH_ACTIONS_PERMISSIONS).then(() => {
        filterAndSetActions();
      });
      return;
    }

    // Si ya habian solo se filtran
    filterAndSetActions();
  },
  [FETCH_USERS](context) {
    ApiService.get(
      context.getters.coreApiUrl + "user_user_type/application",
      context.getters.idApp
    ).then(({ data }) => {
      context.commit(SET_USERS, data);
    });
  },
};

const mutations = {
  [SET_UUID_AS_HEADER](state, uuid) {
    state.studentUUID = uuid;
    UUIDservice.saveToken(state.studentUUID);
  },
  [SET_USER_TYPE](state, userType) {
    state.userType = userType;
  },
  [SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },
  [SET_ACTIONS](state, actions) {
    state.actions = actions;
  },
  [SET_CURRENT_PAGE_ACTIONS](state, currentPageActions) {
    state.currentPageActions = currentPageActions;
  },
  [PURGE_CURRENT_PAGE_ACTIONS]() {
    state.currentPageActions = [];
  },
  [SET_USERS](state, users) {
    state.users = users;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
