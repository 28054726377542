export const GET_NURSING_CATALOG_INFO = "getCatalogsInfo";
export const SET_NURSING_CATALOG_INFO = "setCatalogsInfo";

const state = {
    nursing_catalogs: {},
};

const getters = {
    nursingCatalogsInfo(state) {
        return state.nursing_catalogs;
    },
};

const actions = {
    [GET_NURSING_CATALOG_INFO](context, payload) {
        context.commit(SET_NURSING_CATALOG_INFO, payload);
    },
};

const mutations = {
    [SET_NURSING_CATALOG_INFO](state, catalogs_object) {
        state.nursing_catalogs = catalogs_object;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};