import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/core/:token?",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/general",
          name: "general",
          component: () => import("@/view/pages/Base.vue"),
          children: [
            {
              path: "/academic_levels",
              name: "academic_levels",
              component: () => import("@/view/pages/academic_levels"),
            },
            {
              path: "/grades",
              name: "grades",
              component: () => import("@/view/pages/grades"),
            },
            {
              path: "/student_associations",
              name: "student_associations",
              component: () => import("@/view/pages/students_associations"),
            },
            {
              path: "/groups",
              name: "groups",
              component: () => import("@/view/pages/groups"),
            },
            {
              path: "/education_departments",
              name: "education_departments",
              component: () => import("@/view/pages/education_departments"),
            },
            {
              path: "/sections",
              name: "sections",
              component: () => import("@/view/pages/sections"),
            },
            {
              path: "/specialities",
              name: "specialities",
              component: () => import("@/view/pages/specialities"),
            },
          ],
        },
        {
          path: "/pedagogical_diary",
          name: "pedagogical_diary",
          component: () => import("@/view/pages/Base.vue"),
          children: [
            //ZONES

            {
              path: "/zones",
              name: "zones",
              component: () => import("@/view/pages/zones"),
            },
            {
              path: "/areas",
              name: "areas",
              component: () => import("@/view/pages/areas"),
            },
            {
              path: "/student_class",
              name: "student_class",
              component: () => import("@/view/pages/student_class"),
            },
            {
              path: "/student_class_record",
              name: "student_class_record",
              component: () => import("@/view/pages/studentClassRecord"),
            },
            {
              path: "/locals",
              name: "locals",
              component: () => import("@/view/pages/locals"),
            },
            //CODES
            {
              path: "/code_places",
              name: "code_places",
              component: () => import("@/view/pages/code_places"),
            },

            {
              path: "/codes",
              name: "codes",
              component: () => import("@/view/pages/codes"),
            },
            {
              path: "/justification_headers",
              name: "justification_headers",
              component: () => import("@/view/pages/justification_headers"),
            },
            {
              path: "/student_justifications",
              name: "student_justifications",
              component: () => import("@/view/pages/student_justifications"),
            },
            {
              path: "/lateness",
              name: "lateness",
              component: () => import("@/view/pages/lateness"),
            },
            {
              path: "/justifications",
              name: "justifications",
              component: () => import("@/view/pages/justifications"),
            },
            {
              path: "/permissions",
              name: "permissions",
              component: () => import("@/view/pages/permissions"),
            },
            {
              path: "/teacher_groups_date",
              name: "teacher_groups_date",
              component: () => import("@/view/pages/teacher_groups_date"),
            },
            {
              path: "/daily_block",
              name: "daily_block",
              component: () => import("@/view/pages/daily_block"),
            },
            {
              path: "/schedules",
              name: "schedules",
              component: () => import("@/view/pages/schedules"),
            },
            {
              path: "/teachers-schedules",
              name: "teachers-schedules",
              component: () => import("@/view/pages/teacher_schedules"),
            },
            {
              path: "/assign_code",
              name: "assign_code",
              component: () => import("@/view/pages/assign_code"),
            },
            {
              path: "/create_observation",
              name: "create_observation",
              component: () => import("@/view/pages/observations"),
            },
            {
              path: "/discipline_dashboard",
              name: "discipline_dashboard",
              component: () =>
                import("@/view/pages/student_discipline_dashboard"),
            },
            {
              path: "/discipline_reports",
              name: "discipline_reports",
              component: () => import("@/view/pages/discipline-reports"),
            },
            {
              path: "/improve_code_report",
              name: "improve_code_report",
              component: () =>
                import("@/view/pages/discipline-reports/ImproveCode.vue"),
            },
            {
              path: "/absences_report",
              name: "absences_report",
              component: () =>
                import("@/view/pages/discipline-reports/JustifyAbsences.vue"),
            },
            {
              path: "/late_arrival_report",
              name: "late_arrival_report",
              component: () =>
                import("@/view/pages/discipline-reports/LateArrivals.vue"),
            },
          ],
        },
        {
          path: "/enrollment",
          name: "enrollment",
          component: () => import("@/view/pages/Base.vue"),
          children: [
            {
              path: "/enrollment_reports",
              name: "enrollment_reports",
              component: () =>
                import(
                  "@/view/pages/enrollment_processes/enrollment_reports.vue"
                ),
            },
            {
              path: "/enrollment_processes",
              name: "enrollment_processes",
              component: () => import("@/view/pages/enrollment_processes"),
            },
            {
              path: "/manage_students_families",
              name: "manage_students_families",
              component: () => import("@/view/pages/manage_students_families"),
            },
          ],
        },
        {
          path: "/nursing",
          name: "nursing",
          component: () => import("@/view/pages/Base.vue"),
          children: [
            {
              path: "/clinical_nursing",
              name: "clinical_nursing",
              component: () => import("@/view/pages/clinical_nursing"),
            },
            {
              path: "/clinical_nursing/new_student_clinical_visit",
              name: "new_student_clinical_visit",
              component: () =>
                import(
                  "@/view/pages/clinical_nursing/new_student_medical_visit"
                ),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/clinical_nursing/new_external_clinical_visit",
              name: "new_external_clinical_visit",
              component: () =>
                import(
                  "@/view/pages/clinical_nursing/new_external_medical_visit"
                ),
              props(route) {
                return route.query || {};
              },
            },
          ],
        },
        {
          path: "/academic_managment",
          name: "academic_managment",
          component: () => import("@/view/pages/Base.vue"),
          children: [
            {
              path: "/subject_types",
              name: "subject_types",
              component: () => import("@/view/pages/subject_types"),
            },
            {
              path: "/subject_evaluation_scales",
              name: "subject_evaluation_scales",
              component: () => import("@/view/pages/subject_evaluation_scales"),
            },
            {
              path: "/activity_categories",
              name: "activity_categories",
              component: () => import("@/view/pages/activity_categories"),
            },
            {
              path: "/activity_types",
              name: "activity_types",
              component: () => import("@/view/pages/activity_types"),
            },
            {
              path: "/evaluation_types",
              name: "evaluation_types",
              component: () => import("@/view/pages/evaluation_types"),
            },
            {
              path: "/subjects",
              name: "subjects",
              component: () => import("@/view/pages/subjects"),
            },
            {
              path: "/stage_types",
              name: "stage_types",
              component: () => import("@/view/pages/stage_types"),
            },
            {
              path: "/stages",
              name: "stages",
              component: () => import("@/view/pages/stages"),
            },
            {
              path: "/teacher_subjects",
              name: "teacher_subjects",
              component: () => import("@/view/pages/teacher_subjects"),
            },
            {
              path: "/evaluations",
              name: "evaluations",
              component: () => import("@/view/pages/evaluations"),
            },
            {
              path: "/subject_evaluations",
              name: "subject_evaluations",
              component: () =>
                import("@/view/pages/evaluations/subject_evaluations.vue"),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/create_evaluation",
              name: "create_evaluation",
              component: () =>
                import("@/view/pages/evaluations/create_evaluation.vue"),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/evaluation",
              name: "evaluation",
              component: () =>
                import("@/view/pages/evaluations/evaluation.vue"),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/sub_evaluation_scores",
              name: "sub_evaluation_scores",
              component: () =>
                import("@/view/pages/evaluations/sub_evaluation_scores.vue"),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/score_correction",
              name: "score_correction",
              component: () =>
                import("@/view/pages/score_correction/index.vue"),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/scores_amend_form",
              name: "scores_amend_form",
              component: () =>
                import("@/view/pages/requests/scores_amend_form.vue"),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/requests",
              name: "requests",
              component: () => import("@/view/pages/requests/RequestIndex.vue"),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/correction_notes_extension_of_profiles_request",
              name: "correction_notes_extension_of_profiles_request",
              component: () => import("@/view/pages/requests/index.vue"),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/request_reasons",
              name: "request_reasons",
              component: () => import("@/view/pages/request_reasons"),
            },
            {
              path: "/my_requests",
              name: "my_requests",
              component: () =>
                import("@/view/pages/requests/MyRequestIndex.vue"),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/my_correction_notes_extension_of_profiles_request",
              name: "my_correction_notes_extension_of_profiles_request",
              component: () => import("@/view/pages/requests/my_requests.vue"),
              props(route) {
                return route.query || {};
              },
            },
            {
              path: "/my_deferred_assistance_requests",
              name: "my_deferred_assistance_requests",
              component: () =>
                import("@/view/pages/requests/my_deferred_attendance.vue"),
              props(route) {
                return route.query || {};
              },
            },
          ],
        },
        {
          path: "/student_managment",
          name: "student_managment",
          component: () => import("@/view/pages/Base.vue"),
          children: [
            {
              path: "/students",
              name: "students",
              component: () => import("@/view/pages/students"),
            },
            {
              path: "/associate_students",
              name: "associate_students",
              component: () => import("@/view/pages/associate_students"),
            },
            {
              path: "/group_assignments",
              name: "group_assignments",
              component: () => import("@/view/pages/group_assignments"),
            },
            // {
            //   path: "/reports",
            //   name: "reports",
            //   component: () => import("@/view/pages/reports"),
            // },
            {
              path: "/reports",
              name: "reports",
              component: () => import("@/view/pages/reports"),
            },
            {
              path: "/events",
              name: "events",
              component: () => import("@/view/pages/events"),
            },
            {
              path: "/academic_performance_report",
              name: "academic_performance_report",
              component: () =>
                import("@/view/pages/reports/academic_performance_report.vue"),
            },
            {
              path: "/grade_certificate",
              name: "grade_certificate",
              component: () =>
                import("@/view/pages/reports/grade_certificate.vue"),
            },
            {
              path: "/academic_or_technical_student_group_list",
              name: "academic_or_technical_student_group_list",
              component: () =>
                import(
                  "@/view/pages/reports/academic_or_technical_student_group_list.vue"
                ),
            },
            {
              path: "/score_summary",
              name: "score_summary",
              component: () =>
                import("@/view/pages/reports/student_score_summary.vue"),
            },
            {
              path: "/report_card",
              name: "report_card",
              component: () => import("@/view/pages/reports/report_card.vue"),
            },
            {
              path: "/report_card_delivery_acknowledgement",
              name: "report_card_delivery_acknowledgement",
              component: () =>
                import(
                  "@/view/pages/reports/report_card_delivery_acknowledgement.vue"
                ),
            },
            {
              path: "/report_card_by_subject",
              name: "report_card_by_subject",
              component: () =>
                import("@/view/pages/reports/report_card_for_siges.vue"),
            },
            {
              path: "/student-absences-report",
              name: "student-absences-report",
              component: () =>
                import("@/view/pages/reports/student_absences_report.vue"),
            },
            {
              path: "/teacher_entered_scores",
              name: "teacher_entered_scores",
              component: () =>
                import("@/view/pages/reports/teacher_entered_scores.vue"),
            },
            {
              path: "/teacher_faculty",
              name: "teacher_faculty",
              component: () =>
                import("@/view/pages/reports/teacher_faculty.vue"),
            },
            {
              path: "/registered_students",
              name: "registered_students",
              component: () =>
                import("@/view/pages/reports/registered_students.vue"),
            },
            {
              path: "/academic_excellence",
              name: "academic_excellence",
              component: () =>
                import("@/view/pages/reports/academic_excellence.vue"),
            },
            {
              path: "/students_profile_pictures_by_group",
              name: "students_profile_pictures_by_group",
              component: () =>
                import(
                  "@/view/pages/reports/student_profile_picture_by_group.vue"
                ),
            },
            {
              path: "/student_record_report",
              name: "student_record_report",
              component: () =>
                import("@/view/pages/reports/student_record.vue"),
            },
            {
              path: "/student_report_with_photo",
              name: "student_report",
              component: () =>
                import("@/view/pages/reports/student_report_with_photo.vue"),
            },
            {
              path: "/student_enrolled_resume",
              name: "student_enrolled_resume",
              component: () =>
                import("@/view/pages/reports/student_enrolled_resume.vue"),
            },
          ],
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
        },
        {
          path: "/custom-wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue"),
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue"),
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue"),
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue"),
            },
          ],
        },
        {
          path: "/custom-plugins",
          name: "plugins",
          component: () => import("@/view/pages/plugins/Plugins.vue"),
          children: [
            {
              path: "cropper",
              name: "cropper",
              component: () => import("@/view/pages/plugins/Cropper.vue"),
            },
            {
              path: "treeselect",
              name: "treeselect",
              component: () => import("@/view/pages/plugins/Treeselect.vue"),
            },
          ],
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "profile-1",
              name: "profile-1",
              component: () => import("@/view/pages/profile/Profile-1.vue"),
            },
            {
              path: "profile-2",
              name: "profile-2",
              component: () => import("@/view/pages/profile/Profile-2.vue"),
            },
            {
              path: "profile-3",
              name: "profile-3",
              component: () => import("@/view/pages/profile/Profile-3.vue"),
            },
            {
              path: "profile-4",
              name: "profile-4",
              component: () => import("@/view/pages/profile/Profile-4.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/Base"),
      redirect: "/unauthenticated",
      children: [
        // {
        //   name: "login",
        //   path: "/login",
        //   component: () => import("@/view/pages/auth/login_pages/Login-1"),
        // },
        // {
        //   name: "register",
        //   path: "/register",
        //   component: () => import("@/view/pages/auth/login_pages/Login-1"),
        // },
        {
          name: "unauthenticated",
          path: "/unauthenticated",
          component: () => import("@/view/pages/auth/Unauthenticated"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Custom-Error.vue"),
    },
  ],
});
