import ApiService from "@/core/services/api.service";

/**
 * POST request to create a new log
 * @param payload
 * @returns {*}
 */
export const addLog = (payload) => {
    return ApiService.post(`logs`, payload);
};

export default {
    addLog,
};