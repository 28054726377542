import axios from "axios";
import UUIDservice from "@/core/services/uid.service";

require("dotenv").config();

let studentInformationClient;

export const StudentInformationService = {
  init() {
    let baseURL;
    if (process.env.ADMISION_APP_ENV === "production") {
      baseURL = process.env.ADMISION_API_URL;
    } else if (process.env.ADMISION_APP_ENV === "development") {
      baseURL = process.env.ADMISION_DEV_API_URL;
    } else {
      baseURL = process.env.ADMISION_LOCAL_API_URL;
    }

    studentInformationClient = axios.create({
      baseURL,
      headers: {
        Authorization: `${UUIDservice.getToken()}`,
        "Content-Type": "application/json",
      },
    });
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    studentInformationClient.defaults.headers.common[
      "Authorization"
    ] = `${UUIDservice.getToken()}`;
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "", headers = "") {
    let path;
    if (slug == "") {
      path = `${resource}`;
    } else if (Array.isArray(slug)) {
      const plainSlug = slug.join("/");
      path = `${resource}/${plainSlug}`;
    } else {
      path = `${resource}/${slug}`;
    }
    return studentInformationClient.get(path, headers).catch((error) => {
      // console.log(error);
      throw new Error(`[KT] StudentInformationService ${error}`);
    });
  },
};

export default StudentInformationService;
